import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";


const SalonMatrix = () => {

  return (
    <section className={'relative bg-gray-50'}>
      <div className={'relative max-w-6xl mx-auto px-4 sm:px-6'}>
        <div className={'py-12 flex flex-col lg:flex-row'}>

          <a className={'lg:w-1/3 py-8 px-4 md:px-16 flex items-center justify-center bg-black mb-8 lg:mb-0'}
             href={'https://www.matrixhaircare.co.uk/'} target={'_blank'} rel="noreferrer"
          >
            <StaticImage
              src={'../images/matrix-logo.png'}
              alt={"Matrix Hair Products"}
              placeholder={"blurred"}
            />
          </a>


          <div className={'lg:w-2/3 px-8 flex flex-col justify-center'}>
            <h3 className={'text-black text-xl lg:text-lg uppercase font-bold mb-2'}>Vegan & Cruelty Free</h3>
            <p className={'font-light'}>
              We are proud to exclusively use Matrix colour and styling products for their superior quality,
              and everything that we use in the salon is vegan and not tested on animals.
            </p>
          </div>

        </div>
      </div>
    </section>
  )
}


export default SalonMatrix;