import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const SalonPhotos = () => {

  return (
    <section>
      <div className={'relative max-w-6xl mx-auto px-4 sm:px-6 grid lg:grid-cols-2 gap-4 lg:gap-6 mb-16'}>
        <div className={`lg:col-span-2`}>
          <StaticImage src={"../images/salon-1.jpg"} alt={'Adrian Rowe Haircutters, inside the salon'}/>
        </div>
        <div>
          <StaticImage src={"../images/salon-2.jpg"} alt={'Adrian Rowe Haircutters, inside the salon'}/>
        </div>
        <div>
          <StaticImage src={"../images/salon-3.jpg"} alt={'Adrian Rowe Haircutters, inside the salon'}/>
        </div>
      </div>
    </section>
  );

}

export default SalonPhotos;