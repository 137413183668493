import * as React from "react";

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import PageTitle from '../components/PageTitle';
import TitleSection from "../components/TitleSection";
import SalonPhotos from "../components/SalonPhotos";
import LocationOpeningTimes from "../components/LocationOpeningTimes";
import BookingForm from "../components/BookingForm";
import SalonMatrix from "../components/SalonMatrix";


const OurSalon = () => (
  <Layout>
    <Seo title="Our Salon" />
    <PageTitle title={'Our Salon'}/>

    <TitleSection title={"Our Salon"}>
      <div className={`flex flex-col md:flex-row md:space-x-12`}>

        <div className={`md:w-1/3`} data-aos={"fade"} data-aos-delay={"200"}>
          <p className={`text-lg font-normal mb-4 md:mb-0`}>
            Our hair salon is conveniently located in Eaton, just south of Norwich (off the A11 Newmarket Road), with ample free car
            parking for all our clients.
          </p>
        </div>

        <div className={`md:w-2/3 space-y-4`} data-aos={"fade"} data-aos-delay={"400"}>
          <p className={`font-light`}>
            Once you step inside, our focus is on you. All consultations are complimentary, where one of our stylists can
            discuss all your wants and desires and then work with you to create a look that suits your personality and lifestyle.
          </p>
          <p className={`font-light`}>
            Our talented team of stylists are all expertly trained to an advanced level and are current with the latest styles,
            trends, and techniques. Whether you’re looking for a blow-dry, a trim, or a full makeover, we promise the highest standards
            of hairdressing and the very best results.
          </p>
        </div>

      </div>
    </TitleSection>

    <SalonPhotos/>
    <SalonMatrix/>
    <LocationOpeningTimes/>
    <BookingForm/>

  </Layout>
)

export default OurSalon;
